.App {
  text-align: center;
}

.difficulty {
  margin: 0 5px;
}

.GameBoard {
  margin: 0 auto;
}

.Cell {
  border: 1px solid black;
  height: 20px;
  width: 20px;
}

.hidden {
  background-color: gray;
  color: transparent;
}

.X {
  background-color: red;
  font-weight: bold;
}

.F {
  background-color: yellow;
}

.zero {
  background-color: rgb(207, 205, 205);
  color: transparent;
}

.one {
  color: blue;
  font-weight: bold;
}

.two {
  color: green;
  font-weight: bold;
}

.three {
  color: red;
  font-weight: bold;
}

.four {
  color: navy;
  font-weight: bold;
}

.five {
  color: darkred;
  font-weight: bold;
}

.six {
  color: teal;
  font-weight: bold;
}

.seven {
  color: black;
  font-weight: bold;
}

.seven {
  color: gray;
  font-weight: bold;
}

/************** Footer Styling *************/

.Footer {
  position: relative;
  height: 5rem;

}

.Footer-Text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
}

.Footer-Text a {
  text-decoration: none;
  color: black;

}

.Footer-Text a:visited {
  color: black;

}


